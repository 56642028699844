import {domReady} from '@roots/sage/client';
import Splide from '@splidejs/splide';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  Alpine.directive(
    'intersect-animate',
    // eslint-disable-next-line no-unused-vars
    (el, {value, expression, modifiers}, {evaluateLater, cleanup}) => {
      let observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            (!entry.isIntersecting && value === 'enter') ||
            (entry.isIntersecting && value === 'leave') ||
            (entry.intersectionRatio === 0 && !value)
          )
            return;

          let classes = ['animate-animated'];
          expression.split(' ').forEach((c) => classes.push(`animate-${c}`));
          el.classList.add(...classes);

          observer.disconnect();
        });
      });

      observer.observe(el);

      cleanup(() => {
        observer.disconnect();
      });
    },
  );
  Alpine.plugin(intersect);
  Alpine.plugin(focus);
  window.Alpine = Alpine;

  //qui codice alpine
  //MODAL

  //FINE MODAL

  Alpine.start();

  let splideElements = document.querySelectorAll(
    '.splide:not(.splide-thumbnails-with-lightbox)',
  );

  splideElements.forEach((el) => {
    let elItems = el.querySelectorAll('.splide__slide');

    if (elItems.length > 0) {
      let elSplide = new Splide(el);
      //let elArrows = el.parentElement.querySelector('.splide-arrows');
      let elThumbs = el.parentElement.querySelector(
        '.splide-thumbnails-with-lightbox',
      );
      let elThumbsSplide;
      if (elThumbs) {
        elThumbsSplide = new Splide(elThumbs);
        elSplide.sync(elThumbsSplide);
      }
      elSplide.mount();
      if (elThumbs) {
        elThumbsSplide.mount();
      }
    } else {
      el.classList.add('is-active', 'is-initialized', 'is-one-slide');
    }
  });
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
import.meta.webpackHot?.accept(main);
